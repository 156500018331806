<template>
  <div class="page-wrapper login">
    <div class="page-inner bg-brand-gradient">
      <div class="page-content-wrapper bg-transparent m-0">
        <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
          <div class="d-flex align-items-center container p-0">
            <div class="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9">
              <a href="javascript:void(0)"
                 class="page-logo-link press-scale-down d-flex align-items-center">
                <img :src="publicPath + 'media/logo-summer.svg'"  class="logo-edutalk" alt="Edutalk Tư Vấn"
                     aria-roledescription="logo">
                <span class="page-logo-text mr-1"></span>
              </a>
            </div>
          </div>
        </div>
        <div class="flex-1" :style="{ background: 'url(media/svg/pattern-1.svg) no-repeat center bottom fixed', backgroundSize: 'cover'}">
          <div class="container">

            <div class="maintain text-center">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <div class="animated fadeIn visible" data-animation="fadeIn" data-animation-delay="500">
                    <h2 class="uppercase yellow">Bảo trì Website Edutalk</h2>
                    <h4 class="violet yellow">Vui lòng quay lại sau thời gian bảo trì còn lại:</h4>
                  </div>
                </div>
              </div>
              <vue-countdown :time="time" v-slot="{ days, hours, minutes, seconds }">
                <!-- Watch -->
                <div id="watch" class="animated" data-animation="fadeIn" data-animation-delay="600">
                  <!-- Days -->
                  <div class="dash days_dash">
                    <div class="digit">{{ days > 9 ? days : '0' + days  }}</div>
                    <span class="dash_title">Ngày</span>
                  </div>
                  <!-- End Days -->

                  <!-- Hours -->
                  <div class="dash hours_dash">
                    <div class="digit">{{ hours > 9 ? hours : '0' + hours  }}</div>
                    <span class="dash_title">Giờ</span>
                  </div>
                  <!-- End Hours -->

                  <!-- Minutes -->
                  <div class="dash minutes_dash">
                    <div class="digit">{{ minutes > 9 ? minutes : '0' + minutes }}</div>
                    <span class="dash_title">Phút</span>
                  </div>
                  <!-- End Minutes -->

                  <!-- Seconds -->
                  <div class="dash seconds_dash">
                    <div class="digit">{{ seconds > 9 ? seconds : '0' + seconds  }}</div>
                    <span class="dash_title">Giây</span>
                  </div>
                  <!-- End Seconds -->
                </div>
              </vue-countdown>


              <div class="row">
                <div class="col-sm-12 text-center">
                  <div class="animated fadeIn visible" data-animation="fadeIn" data-animation-delay="500">
                    <div class="boy">
                      <div class="boy_tv">
                        <div class="screen"></div>
                      </div>
                      <div class="antenna"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
              2021 © Edutalk by&nbsp;<a href="https://edutalk.edu.vn"
                                        class="text-white opacity-40 fw-500" title="edutalk.edu.vn"
                                        target="_blank">edutalk.edu.vn</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
// import CodeInput from "vue-verification-code-input";
export default {
  name: "maintain",
  components: {
    VueCountdown
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      time: 0
    };
  },
  mounted() {
    let time = localStorage.getItem('maintain');
    let currentTime = new Date();
    let dateTime = new Date(time);
    this.time = dateTime.getTime() - currentTime.getTime();
  }
}
</script>
